import { Center, Link } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { TopBarItems } from './utils'

type Props = {
  items: TopBarItems;
};

const TopBar = ({ items }: Props) => {
  const { t } = useTranslation()
  const [activeItem, setActiveItem] = useState(0)

  useEffect(() => {
    if (!items.length) {
      return
    }

    const interval = setInterval(() => {
      setActiveItem(activeItem => (activeItem + 1) % items.length)
    }, 5000)

    return () => clearInterval(interval)
  }, [items])

  if (!items.length) {
    return null
  }

  return (
    <Center bg="black" h="50px" className="topBar">
      {items.map(({ text, url }, index) => {
        const isVisible = index === activeItem
        return (
          <Link
            key={text}
            visibility={isVisible ? 'visible' : 'hidden'}
            opacity={isVisible ? '1' : '0'}
            position="absolute"
            padding={'4px 10px'}
            transition="all 500ms ease-in-out"
            href={url}
            color="white"
            textAlign="center"
            fontSize={{ base: 'xs', sm: 'sm' }}
          >
            {t(text)}
          </Link>
        )
      })}
    </Center>
  )
}

export default TopBar
